import axios from 'axios';
import { showLoading, hideLoading } from '../utils/loading';
import { Toast } from 'antd-mobile';
let apiBaseUrl = 'https://jd.proxy.fly-ad.cn'
if (process.env.NODE_ENV === 'development') {
  apiBaseUrl = 'https://apis.yj-zn.com';
} else {
  apiBaseUrl = 'https://apis.yj-zn.com'
}
const axiosInstance = axios.create({
  baseURL: apiBaseUrl
});
//请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    showLoading();
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    //若请求方式为post，则将data参数转为JSON字符串
    if (config.method === "POST") {
      config.data = JSON.stringify(config.data);
    }
    return config;
  },
  (error) =>
    // 对请求错误做些什么
    Promise.reject(error)
);

//响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    hideLoading();
    if (response.data.status == 402 || response.data.status == 403) {
      localStorage.removeItem('token')
      setTimeout(() => {
        // router.push("/");
        localStorage.setItem('navIndex', '2')
        let local = window.location
        window.location.href = local.protocol + '//' + local.host + '/home/my'
      }, 2000)
    }
    if (response.data.status != '200' && response.data.status <= '900' && response.data.status != '606') {
      Toast.show({
        icon: 'fail',
        content: response.data.message,
      })
    }
    //响应成功
    // console.log('拦截器报错');

    else return response;
  },
  (error) => {
    //响应错误
    hideLoading();
    let message = "";
    if (error.response && error.response.status) {
      const status = error.response.status;
      switch (status) {
        case 400:
          message = "请求错误";
          break;
        case 401:
          message = "请求错误";
          break;
        case 404:
          message = "请求地址出错";
          break;
        case 408:
          message = "请求超时";
          break;
        case 500:
          message = "服务器内部错误!";
          break;
        case 501:
          message = "服务未实现!";
          break;
        case 502:
          message = "网关错误!";
          break;
        case 503:
          message = "服务不可用!";
          break;
        case 504:
          message = "网关超时!";
          break;
        case 505:
          message = "HTTP版本不受支持";
          break;
        default:
          message = "请求失败";
      }

      // ElMessage.error(message);
      //return Promise.reject(error);
      Toast.show({
        icon: 'fail',
        content: message,
      })
    } else {
      //return Promise.reject(error);
      Toast.show({
        icon: 'fail',
        content: '系统开小差，请刷新重试',
      })
    }
  }
);
const postRequest = (endpoint, data = null, config) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: endpoint,
      data: data,
      headers: {
        'X-TOKEN': localStorage.getItem('token') || '',
        "Content-Type": "application/json",
      }
    })
      .then(response => {
        if (response) {
          if (response.status >= 200 && response.status < 300) {
            resolve(response.data);
          } else {
            // console.log(response)
            reject(new Error(`Request failed with status code ${response.status}`));
          }
        }
      })
      .catch(error => {
        // reject(error);
        console.log(error, '报错报错2000不是')
        Toast.show({
          icon: 'fail',
          content: '',
        })
      });
  });
};
const getRequest = (endpoint, data = null) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: endpoint,
      params: data,
      headers: {
        'X-TOKEN': localStorage.getItem('token') || '',
        "Content-Type": "application/json",
      }
    })
      .then(response => {
        // console.log(response, '0-0-0-0-')
        if (response) {
          if (response.status && response.status >= 200 && response.status < 300) {
            resolve(response.data);
          } else {
            reject(new Error(`Request failed with status code ${response.status}`));
          }
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
const getBlobRequest = (endpoint, data = null) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: endpoint,
      params: data,
      responseType: 'blob',
      headers: {
        'X-TOKEN': localStorage.getItem('token') || '',
      }
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response);
        } else {
          reject(new Error(`Request failed with status code ${response.status}`));
        }
      })
      .catch(error => {
        reject(error);
        //alert(error)
      });
  });
};
const uploadRequest = (endpoint, data = null) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: endpoint,
      data: data,
      headers: {
        'X-TOKEN': localStorage.getItem('token') || '',
        "Content-Type": "Content-Type: multipart/form-data",
      }
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.data);
        } else {
          // Toast.show({
          //   icon: 'fail',
          //   content: response.data.message,
          // })
        }
      })
      .catch(error => {
        reject(error);
        Toast.show({
          icon: 'fail',
          content: error,
        })
      });
  });
}
export { postRequest, getRequest, uploadRequest, getBlobRequest }
