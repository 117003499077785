import Loading from "../components/loading"
import ReactDOM from 'react-dom/client';
let loadingCount  = 0
let title="正在加载中"
//开启loading
export const showLoading = (val) =>{
    if(val){
      title=val
    }
    if(loadingCount === 0){
         let dom = document.createElement("div")
         dom.setAttribute("id","loadbox")
         document.body.appendChild(dom)
         ReactDOM.createRoot(dom).render(<Loading title={title}></Loading>)
    } 
    loadingCount++
}
export const hideLoading = ()=>{
    if(loadingCount <=0 ) return;
    loadingCount--
    if(loadingCount === 0){
       document.body.removeChild(document.getElementById('loadbox'))
    }
}
