import { useEffect } from 'react'
import '../assets/loading.scss'
export default function Loading(props){
    useEffect(()=>{
      console.log(props,'000-0-')
    },[])
    return (
        <div className="loadbox">
             <img src="https://feed.youshu.cc/readwith/media/picture/5b20dae9bfd3d.gif"></img>
        </div>
    )
}