import { Route,Routes } from 'react-router-dom';
import {lazy} from "react";
const Chat = lazy(() => import("../pages/chat")) 
const Member = lazy(() => import("../pages/member")) 
const IndexHome = lazy(()=>import("../pages/index"))
const Extend = lazy(()=>import("../pages/extend"))
const PayResult = lazy(()=>import("../pages/paySuccess"))
const Order = lazy(()=>import("../pages/order"))
const Result = lazy(()=>import("../pages/result"))
const Works = lazy(()=>import("../pages/works"))
const Gold = lazy(()=>import("../pages/gold_coins"))
const Promoter = lazy(()=>import("../pages/promoter"))
const Lottery = lazy(()=>import("../pages/lottery"))
const Private = lazy(()=>import("../pages/private"))
const Merchat = lazy(()=>import("../pages/merchat"))
function RootRoute(){
   return (
     <>
       <Routes>
           <Route path="/" exact element={<IndexHome/>} />
           <Route path='/home/*' element={<IndexHome/>}></Route>
           <Route path='/chat' element={<Chat/>}></Route>
           <Route path='/member' element={<Member/>}></Route>
           <Route path='/extend' element={<Extend/>}></Route>
           <Route path='/payResult' element={<PayResult/>}></Route>
           <Route path='/order' element={<Order></Order>}></Route>
           <Route path='/result/:id' element={<Result></Result>}></Route>
           <Route path='/merchat/:id' element={<Merchat></Merchat>}></Route>
           <Route path='/works' element={<Works></Works>}></Route>
           <Route path='/goldCoin' element={<Gold></Gold>}></Route>
           <Route path='/promoter' element={<Promoter></Promoter>}></Route>
           <Route path='/lottery' element={<Lottery></Lottery>}></Route>
           <Route path='/private' element={<Private></Private>}></Route>
       </Routes>
     </>
   )
}
export default RootRoute;