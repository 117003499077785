
// export default App;
import React, { Suspense, useEffect, useState } from 'react'
import RootRoute from './routers/router';
import { useLocation } from 'react-router-dom';
import WechatSDKComponent from './components/wechatsdk';
export default function App() {
  const location = useLocation();
  const [timer,setTimer] = useState()
  useEffect(() => {
    // 在每次路由变化时执行的代码
    console.log('CommonComponent 被重新加载');
    setTimer(new Date().getTime())
  }, [location.pathname]);
  return (
    <>
    <WechatSDKComponent key={timer}></WechatSDKComponent>
    <RootRoute/>
    </>
  )
}
