import React, { useEffect } from 'react';
import { postRequest,getRequest } from '../axios/request';
const WechatSDKComponent = () => {
    useEffect(() => {
        // 在组件挂载时初始化微信 JS-SDK
        initWechatSDK();
        //获取配置
        getConfig()
        
    }, []);
    const initWechatSDK = async () => {
        let share_url = window.location.href
       // alert(share_url)
        // 调用后端接口获取微信 JS-SDK 签名等信息
       await postRequest('/user/v1/config', { 'url': share_url }).then((res) => {
            let id= localStorage.getItem('userId') || ''
            let shareData = {
                title: '云桔AI·您最好的虚拟助手', // 分享标题
                link: window.location.protocol + '//' + window.location.host+'/home?prompt_id='+id,
                imgUrl: 'https://apis.yj-zn.com/file/v1/view/202312151735657392059944960.jpg', // 分享图标
                desc: '把重复工作交给AI，让您的生活更轻松。'
            }
            if (res.status == 200) {
                //console.log(window.location.protocol + '//' + window.location.host)
                wexinShare(res.result, shareData)
            }
        })
    };

    return (
        <div>

        </div>
    );
    function getConfig(){
        getRequest('/cards/v1/getconfig').then((res)=>{
           if(res.status === 200){
              let da= res.result
              // setTextRate(da.imgRate)
              localStorage.setItem('config',JSON.stringify(da))
           }
        })
     }
    function wexinShare(data, shareData) {
        let appId = data.appid;
        let timestamp = data.timestamp;
        let nonceStr = data.nonceStr;
        let signature = data.signature;
        //console.log(data,'00000000')
        window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature, // 必填，签名，见附录1
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        window.wx.ready(function () {
            //分享到朋友圈”及“分享到QQ空间”
            window.wx.updateTimelineShareData({
                title: shareData.title, // 分享标题
                link: shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareData.imgUrl, // 分享图标
                success: function (res) {
                    // 设置成功
                    console.log("分享成功");
                },
                cancel: function (res) {
                    console.log("分享失败")
                }
            })

            //“分享给朋友”及“分享到QQ”
            window.wx.updateAppMessageShareData({
                title: shareData.title, // 分享标题
                desc: shareData.desc, // 分享描述
                link: shareData.link, // 分享链接
                imgUrl: shareData.imgUrl, // 分享图标
                success: function (res) {
                    // 设置成功
                    console.log("分享成功");
                },
                cancel: function (res) {
                    console.log("分享失败")
                }
            })

        });
        window.wx.error(function (res) {
            let b = JSON.stringify(res)
            //alert(b)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
           // console.log('验证失败返回的信息:', res);
        });
    }
};

export default WechatSDKComponent;